import { createSelector } from "reselect";
import {
    decompressRechteToken,
    getArtistMapWithEinnahmenBearbeiten,
    getArtistMapWithSchreibrecht,
    hatRecht,
    RECHT_SCHREIBEN
} from "../../models/auth";
import { selectArtistMap } from "../common/commonSelector";
import { decodeToken } from "../utils/jwt";
import { keyBy, omit } from "../utils/lodash-replacement";

export const selectAuthState = createSelector(
    [(state) => state]
    , (state) => state.auth
)

export const selectStudioAuth = createSelector([selectAuthState], state => state?.studioAuth)


export const selectStudioToken = createSelector([selectStudioAuth], state => state?.studioToken)
export const selectStudioRefreshToken = createSelector([selectStudioAuth], state => state?.studioRefreshToken)
export const selectIsStudioLoginFailed = createSelector([selectAuthState], (state) => Boolean(state.studioAuthFailure))
export const selectIsStudioLoginInProgress= createSelector([selectAuthState], (state) => Boolean(state.studioAuthLoading))

export const selectArtistAuth = createSelector([selectAuthState], (state) => state?.artistAuth)

export const selectArtistToken = createSelector([selectArtistAuth], state => state?.artistToken)
export const selectIsArtistLoggedIn = createSelector([selectArtistAuth], state => Boolean(state?.artistToken))
export const selectArtistRefreshToken = createSelector([selectArtistAuth], state => state?.artistRefreshToken)
export const selectLoggedInArtistID = createSelector(
    [selectArtistRefreshToken]
    , token => token && decodeToken(token).id
)

const selectSelfRechte = createSelector(
    [selectLoggedInArtistID]
    , ArtistID => ArtistID && [{ArtistID, Rechte: 15, EditIncome: true}] || []
)


export const selectDecodedToken = createSelector(
    [selectArtistToken]
    , token => token && decodeToken(token)
)
export const selectLoggedInArtistData = createSelector([selectDecodedToken], (token) => token?.artist)
export const selectLoggedInArtistKuerzel = createSelector([selectLoggedInArtistData], (artist) => artist?.Kuerzel)

export const selectLocale = createSelector(
    [selectLoggedInArtistData]
    , (artist) => artist && artist.Lang !== 'German' && 'en' || 'de'
)

export const selectLoggedInIsAdmin = createSelector([selectLoggedInArtistData], artist => artist?.isAdmin)
export const selectLoggedInCannotEdit = createSelector([selectLoggedInArtistData], artist => Boolean(artist?.CannotEdit))

export const selectLoggedInArtistRechteToken = createSelector([selectDecodedToken], token => token?.rechteToken)

export const selectLoggedInRechte = createSelector(
    [selectLoggedInArtistRechteToken
    , selectSelfRechte]
    , (token, selfRechte) => keyBy(decompressRechteToken(token).concat(selfRechte), 'ArtistID') || {}
)

export const selectLeseRechtArtistIDList = createSelector(
    [selectLoggedInRechte]
    , (rechteMap) => rechteMap && Object.keys(rechteMap).map(t => +t) || []
)


export const selectReadableArtistMap= createSelector(
    [selectArtistMap
    , selectLeseRechtArtistIDList
    , selectLoggedInIsAdmin]
    , (artistMap, artistIdList, isAdmin) => artistMap && (isAdmin ? artistMap : artistIdList && artistIdList.reduce((map, key) => {
        map[key] = artistMap[key]
        return map
    }, {}))
)

export const selectSchreibRechteArtistIDList = createSelector(
    [selectLoggedInRechte]
    , (rechteMap) => rechteMap && Object.values(rechteMap).filter(recht => hatRecht(recht, [RECHT_SCHREIBEN]))
        .map(t => t.ArtistID) || []
)

function selectFromArtistMap(artistIds, artistMap) {
    const omitList = Object.keys(artistMap).filter(k => !artistIds.includes(+k))
    return omit(artistMap, omitList)
}

export const selectArtistMapWithViewRights = createSelector(
    [selectLeseRechtArtistIDList
    , selectArtistMap]
    , (viewableArtistIdList, artistMap) => viewableArtistIdList && artistMap && selectFromArtistMap(viewableArtistIdList, artistMap) || {}
)

export const selectArtistMapWithEinnahmenRights = createSelector(
    [selectArtistMap
    , selectLoggedInArtistID
    , selectLoggedInRechte]
    , (artistMap, loggedInArtistID, rechteMap) => getArtistMapWithEinnahmenBearbeiten(artistMap, loggedInArtistID, rechteMap)
)

export const selectArtistMapWithWriteRights = createSelector(
    [selectArtistMap
    , selectLoggedInArtistID
    , selectLoggedInRechte]
    , (artistMap, loggedInArtistID, rechteMap) => getArtistMapWithSchreibrecht(artistMap, loggedInArtistID, rechteMap)
)


export const selectLoggedInStudioData = createSelector(
    [selectArtistToken]
    , (token) => token && decodeToken(token).studio
)

export const selectStudioDBName = createSelector([selectLoggedInStudioData], studio => studio?.db)
export const selectStudioUserID = createSelector([selectLoggedInStudioData], studio => studio?.UserID)
export const selectHasKissSign = createSelector([selectLoggedInStudioData], studio => studio?.SignPadCount > 0)
export const selectLoggedInStudioDataRestlaufzeit = createSelector(
    [selectLoggedInStudioData]
    , (studio) => studio && Math.floor((new Date(studio.gezBis).getTime() - new Date().getTime()) / (3600 * 1000 * 24)) || 20
)

export const selectLoggedInArtist = createSelector(
    [selectLoggedInArtistID
    , selectArtistMap]
    , (artistId, artistMap) => artistId && artistMap && artistMap[artistId]
)
export const selectIsArtistLoginFailed = createSelector([selectAuthState], state => Boolean(state?.artistAuthFailure))
export const selectIsArtistLoginInProgress= createSelector([selectAuthState], state => Boolean(state?.artistAuthLoading))
export const selectStudioLoginResetResult = createSelector([selectAuthState], state => state?.studioLoginResetResult)
export const selectStudioLoginResetResultError = createSelector([selectStudioLoginResetResult], state => state?.error)
export const selectArtistLoginResetResult = createSelector([selectAuthState], state => state?.artistLoginResetResult)
export const selectArtistLoginResetResultError = createSelector([selectArtistLoginResetResult], state => state?.error)


