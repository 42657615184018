import { createSelector } from "reselect";
import { removeExpiredSecrets } from "../../../models/pads";

export const selectPdfSignSettingState = createSelector(
    [(state) => state]
    , (state) => state.pdfSignSettings
)

export const selectIsPadsLoading = createSelector([selectPdfSignSettingState], state => Boolean(state?.loading))
export const selectPads = createSelector(
    [selectPdfSignSettingState]
    , state => state?.pads?.length ? state.pads.map(p => removeExpiredSecrets(p)) : []
)
export const selectUnfinishedFilesFromPads = createSelector(
    [selectPads]
    , pads => pads.reduce((result, pad) => pad.unfinishedFiles ? result.concat(pad.unfinishedFiles) : result, [])
)